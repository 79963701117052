export const Environment = {
	AppMeta: {
		Environment: 'DEV',
	},
	API: {
		Path: 'https://func-eop-ac-dev-westeu-002.azurewebsites.net/api',
		Headers: {
			'Authorization-ZeissId': '',
		},
		Params: {
			code: 'Q-qvC4pyYR6opw-r2kw4wf4yK5CnfslgqQ-DkGSPpd3gAzFuLcH21Q==',
		},
	},
};
